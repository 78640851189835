<template lang="">
  <Card>
    <template #header> Varlık Bilgileri </template>
      <div class="from-group">
            <MultiSelect
              v-model="owner"
              :options="sellers"
              :multiple="false"
              :defaultValue="owner"
              label="Sahibi"
              placeholder="Sahip Seçiniz..."
            />
          </div>
    <div class="card-sub-header mt-5 mb-5">Gayrimenkul Bilgileri</div>
    <div class="form-group">
      <label for="name">Adı *</label>
      <input type="text" class="form-control" id="name" v-model="name" />
    </div>
    <div class="row">
      <div class="col mb-5">
        <label for="watch" >İzlenecek</label>
        <select name="" v-model="watch" id="watch" class="form-control">
          <option value="" selected>Seçiniz</option>
          <option value="1">Evet</option>
          <option value="0">Hayır</option>
        </select>
      </div>
      <div class="col">
        <label for="property">Varlık Durumu </label>
        <select name="" v-model="status" id="property" class="form-control" required>
          <option value="" selected>Seçiniz</option>
          <option value="1">Evet</option>
          <option value="0">Hayır</option>
        </select>
      </div>
      <div class="col">
        <label for="bank">Banka Gayrimenkul Kodu</label>
        <input type="text" v-model="bank_propertycode" class="form-control" id="bank" />
      </div>
    </div>
    <div class="card-sub-header mt-5 mb-5">Eski Sahibi</div>
    <div class="row">
      <div class="col-md-6 col-12 mt-5 mb-5">
      <label for="customer-name">Müşteri Adı Soyadı</label>
      <input type="text" v-model="oldOwner" class="form-control" id="customer-name" />
    </div>
    </div>
    <div class="col-12">
      <VarlikList />

    </div>
    <div class="form-group d-flex">
      <button class="btn btn-success ml-auto" @click="updateProperty">Kaydet</button>
    </div>
  </Card>
</template>
<script>
import { SELLERS } from "@/core/services/store/seller/seller.module.js";
import {PROPERTY} from "@/core/services/store/property/property.module.js";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      sellers:[],
      owner:{},
      property:[],
      name : "",
      watch:"",
      status:"",
      bank_propertycode:"",
      oldOwner:""
    };
  },
  created(){
    const propertyQuerys= {user_token:this.myUser,property_id:this.$route.params.id}
    this.$store.dispatch(PROPERTY,propertyQuerys).then((response)=>{
      this.property = response[0]
      
    }).then(()=>{
      this.$store.dispatch(SELLERS, {myUser:this.myUser,query:""}).then((response)=>{
          this.sellers=this.setSellerSelectValues(response)
          this.findSelectedOwner()
        }); 
    })
    
    
    
  },
  components: {
    VarlikList: () => import("@/components/property/DetailTabs/VarlikList"),
  },
  methods:{
    setSellerSelectValues(sellers) {
      const setSellerSelect = sellers.map((index, key) => {
        return { value: index._id.$oid, text: index.title };
      });
      return setSellerSelect;
    },
    findSelectedOwner(){
      const selectedOwner = this.sellers.find(seller => seller.value === this.property.owner)
      this.owner= selectedOwner
    },
    updateProperty(){
      let data = {
        owner : this.owner.value,
        name : this.name,
        watch : this.watch,
        status : this.status,
        bank_propertycode : this.bank_propertycode,
        property_id : this.$route.params.id,
        user_token : this.myUser 
      }
      if(this.oldOwner.length > 0 ){
        data["oldOwner"] = this.oldOwner
      }
      const formData = new FormData()
      for (const key in data) {
          formData.append(key, data[key]);
        }

      this.$ApiService
      .put("property/property" , formData)
      .then((res) => {
        this.$generateNotification(
          this,"success", "Varlık Başarıyla Güncellendi."
        )
        this.$router.go(this.$router.currentRoute)

      })
      .catch((err) => {
        this.$generateNotification(
          this,"error","Varlık Güncellenemedi."
        )
      })
    }

  },
  watch:{
    property(){
      if(this.property){
        this.name = this.property.name
        this.watch = this.property.watch
        this.status = this.property.status
        this.bank_propertycode = this.property.bank_propertycode
      }
      
    }
  },
  computed: {
    ...mapGetters([
      "getSellers",
      "myUser"
    ]),
  },
};
</script>

